import { CompetitionsPageContent } from '@/components/Competition'
import { useLanguage } from '@/hooks/Contexts'
import Head from 'next/head'
import { FC, Fragment } from 'react'

export const Home: FC = () => {
  const { navigation: navStr } = useLanguage()
  return (
    <Fragment>
      <Head>
        <meta name='description' content={navStr.meta.home} key='description' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Head>
      <CompetitionsPageContent dataFetched={null} />
    </Fragment>
  )
}
export default Home
